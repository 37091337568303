/**
 * @desc Modules for e-Editions.
 */
(function(window, document, $, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {});

    var eeData = $('*[data-eedition]').data('eedition'),
        hostname = window.location.hostname,
        protocol = window.location.protocol.replace(/\:/g,'') + '://',
        previewSection = '/tncms/admin/action/main/preview/site',
        previewContent = '/content/tncms/preview/default/',
        defaultMode = ($('*[data-eedition]').data('eedition-viewing-mode')) ? $('*[data-eedition]').data('eedition-viewing-mode') : null;

    // Browser
    me.browser = {

        isEdge: function() {
            return /Edge\/[1-9]/.test(navigator.userAgent);
        },

        hasFlash: function() {
            if (navigator.plugins['Shockwave Flash']) {
                return true;
            } else {
                return false;
            }
        },

        hasMsTouch: function() {
            return navigator.msMaxTouchPoints > 0;
        },

        hasTouch: function() {
          return 'ontouchstart' in window || 'onmsgesturechange' in window;
        },

        isIe: function() {
            return /MSIE|Trident/.test(navigator.userAgent);
        },

        isIe11: function() {
            return !!window.MSInputMethodContext && !!document.documentMode;
        },

        isIeNineDown: function() {
            return (document.all && !window.atob);
        },

        isOldIe: function() {
            return !document.getElementsByClassName;
        },

        isAndroid: function() {
            return /\bAndroid\b/.test(navigator.userAgent);
        },

        isSilk: function() {
            return /\bSilk\b/.test(navigator.userAgent);
        },

        isIphone: function() {
            return /\biPhone\b/.test(navigator.userAgent);
        },

        isIpod: function() {
            return /\biPod\b/.test(navigator.userAgent);
        },

        isIpad: function() {
            var _isIpad = /\biPad\b/.test(navigator.userAgent);
            if (!_isIpad && (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || navigator.platform == 'iPad') {
                return true;
            }
            return _isIpad;
        },

        isIos: function() {
            return (me.browser.isIphone() || me.browser.isIpad() || me.browser.isIpod());
        },

        isMobile: function() {
            return /\bMobile\b/.test(navigator.userAgent);
        },

        isTablet: function() {
            return !/\bMobile\b/.test(navigator.userAgent);
        },

        getBrowserName: function() {
            return this.name || function() {
                var userAgent = navigator ? navigator.userAgent.toLowerCase() : 'other';
                if (userAgent.indexOf('chrome') > -1) {
                    return 'chrome';
                } else if (userAgent.indexOf('firefox') > -1) {
                    return 'firefox';
                } else if (userAgent.indexOf('safari') > -1) {
                    return 'safari';
                } else if (userAgent.indexOf('msie') > -1) {
                    return 'ie';
                }
              return userAgent;
            }();
        },

        getActiveXObject: function(name) {
            try {
                return new ActiveXObject(name);
            } catch(e) {}
        },

        getNavigatorPlugin: function(name) {
            for (var key in navigator.plugins) {
                var plugin = navigator.plugins[key];
                if (plugin.name == name) {
                    return plugin;
                }
            }
        },

        hasPDF: function() {
            return this.plugin || function() {
                if(me.getBrowserName() == 'ie') {
                    return me.getActiveXObject('AcroPDF.PDF') || me.getActiveXObject('PDF.PdfCtrl');
                } else {
                    return !!me.getNavigatorPlugin('Adobe Acrobat') || !!me.getNavigatorPlugin('Chrome PDF Viewer') || !!me.getNavigatorPlugin('WebKit built-in PDF');
                }
            }();
        }

    };

    // Image
    me.image = {

        // Load
        load: function(viewMode) {
            if (viewMode === 'image') {
                var img = document.querySelector('.image-plain'),
                    imgSrc = img.getAttribute('data-src');

                if (imgSrc != null) img.setAttribute('src', imgSrc)
                img.removeAttribute('data-src');
            } else {
                /** Text mode map image */
                var img1 = document.getElementById('eedition-image'),
                    img2 = document.getElementById('eedition-image2');
    
                if (img1 && img2) {
                    var imgSrc1,
                        imgSrc2;
                    
                    // Set map img src
                    if (window.matchMedia('(orientation: landscape)').matches || $(window).width() > $(window).height()) {
                        // Desktop size
                        imgSrc1 = img1.getAttribute('data-src-full');
                        imgSrc2 = img2.getAttribute('data-src-full');
                    } else {
                        // Mobile size (zoomable)
                        imgSrc1 = img1.getAttribute('data-src');
                        imgSrc2 = img2.getAttribute('data-src');
                    }
                    
                    if (imgSrc1 != null) img1.setAttribute('src', imgSrc1);
                    img1.removeAttribute('data-src');
                    img1.removeAttribute('data-src-full');
                    
                    if (imgSrc2 != null) img2.setAttribute('src', imgSrc2);
                    img2.removeAttribute('data-src');
                    img2.removeAttribute('data-src-full');
    
                    loadImage(img1).then(function() {
                        me.resize();
                        img1.style.opacity = 1;
                    });
                }
            }
        },

        // Replace
        replace: function(imageSrc) {
            var placeHolder = $('.image-plain'),
                loader = $('.eedition-loader');
            if (placeHolder.attr('src') != imageSrc) {

                // Reset width, height, position, and scale before replacing image
                $('.image-plain').width('').height('');
                me.iscroll.segments.scrollTo(0, 0);
                if (me.browser.isIpad() && me.utilities.isZoom()) {
                    $('.eedition-scroller').width(2 * me.utilities.availableWidth()).height('');
                    me.iscroll.segments.zoom(0.5, 0, 0, 0);
                } else if ((me.browser.isIphone() || me.browser.isIpod()) && me.utilities.isZoom()) {
                    $('.eedition-scroller').width(4 * me.utilities.availableWidth()).height('');
                    me.iscroll.segments.zoom(0.25, 0, 0, 0);
                } else {
                    $('.eedition-scroller').width('').height('');
                    me.iscroll.segments.zoom(1, 0, 0, 0);
                }
                iSeg.refresh();

                // Replace image
                placeHolder.hide();
                loader.show();
                placeHolder
                    .attr('src', imageSrc)
                    .one('load', function() {
                        loader.hide();
                        $(this).fadeIn(500).css('display','block');
                        me.iscroll.segments.refresh();
                    });
            }
        },

        // Preload
        preload: function(images) {
            $(images).each(function() {
                if (me.browser.isIos()) {
                    $('<img/>')[0].src = this;
                } else {
                    $('head').append('<link rel="prefetch" href="' + this + '">');
                }
            });
        },

        // Zoom
        zoom: function(direction) {
            var zoomLevel;
            var zoomLevels;
            var aspectRatio = $('.image-plain')[0].naturalWidth / $('.image-plain')[0].naturalHeight;
            var sizeByWidth = $('#eedition-wrapper').hasClass('page-sizing-width');

            // page sizing by width
            if ($('#eedition-wrapper').hasClass('page-sizing-width')) {
                zoomLevels = [0.25, 0.5, 0.75, 1, 2, 3, 4];
                var minZoom = iSeg.wrapperHeight / (iSeg.wrapperWidth / aspectRatio);
                iSeg.options.zoomMin = minZoom;
                // removes any zoom levels that are lower than minZoom
                for (var i = zoomLevels.length - 1; i >= 0; i--) {
                    if (minZoom > zoomLevels[i]) {
                        zoomLevels.splice(i, 1);
                    }
                }
                zoomLevels.unshift(minZoom);
                // removes second zoom level if it's close to first zoom level
                if (zoomLevels[1] - zoomLevels[0] < 0.125) {
                    zoomLevels.splice(1, 1);
                }

            // page sizing by height
            } else {
                zoomLevels = [1, 2, 3, 4, 5, 6, 7];
            }

            if (direction === 'in') {
                var curScale = iSeg.scale;
                zoomLevel = zoomLevels.indexOf(curScale);

                if (zoomLevel != zoomLevels.length - 1) {
                    newScale = zoomLevels[zoomLevel + 1];

                    if (sizeByWidth && newScale <= 1) {
                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                        setTimeout(function(){
                            $('.eedition-scroller')[0].style["transition-duration"] = '0ms';
                            $('.eedition-scroller').width(iSeg.wrapperWidth / newScale);
                            setTimeout(function(){
                                iSeg.refresh();
                            }, 0);
                        }, 250);

                    } else if (!sizeByWidth) {
                        var imageWidth = $('.image-plain').width() * iSeg.scale;
                        var centerOffset = iSeg.wrapperWidth / 2;
                        var centerRatio =  (centerOffset - iSeg.x) / imageWidth;

                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                        setTimeout(function(){
                            $('.eedition-scroller')[0].style["transition-duration"] = '0ms';
                            if ($('#eedition-segments-container').width() / newScale < $('.image-plain').width()) {
                                if ($('.image-plain').width() * curScale < iSeg.wrapperWidth) {
                                    centerRatio = 0.5;
                                }
                                $('.eedition-scroller').width($('.image-plain').width());
                                iSeg.scrollTo((($('.image-plain').width() * newScale * centerRatio) - centerOffset) * -1, iSeg.y, 0);
                            } else {
                                $('.eedition-scroller').width(iSeg.wrapperWidth / newScale);
                                iSeg.scrollTo(0, iSeg.y, 0);
                            }
                            setTimeout(function(){
                                iSeg.refresh();
                            }, 0);
                        }, 250);
                    } else {
                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                    }
                }
            } else if (direction === 'out') {
                var curScale = iSeg.scale;
                zoomLevel = zoomLevels.indexOf(curScale);

                if (zoomLevel != 0) {
                    newScale = zoomLevels[zoomLevel - 1];

                    if (sizeByWidth && newScale < 1) {
                        $('.eedition-scroller')[0].style["transition-duration"] = '0ms';
                        $('.eedition-scroller').width(iSeg.wrapperWidth / newScale);
                        $('.eedition-scroller').height(iSeg.wrapperWidth / aspectRatio);
                        $('.image-plain').css('width','auto');
                        $('.image-plain').css('height','100%');
                        iSeg.scrollTo((iSeg.scrollerWidth / newScale * curScale - iSeg.wrapperWidth) / -2, iSeg.y, 0);
                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                        setTimeout(function(){
                            iSeg.refresh();
                        }, 250);

                    } else if (!sizeByWidth) {
                        var imageHeight = $('.image-plain').height() * iSeg.scale;
                        var centerOffset = iSeg.wrapperHeight / 2;
                        var centerRatio =  (centerOffset - iSeg.y) / imageHeight;

                        $('.eedition-scroller')[0].style["transition-duration"] = '0ms';
                        if (iSeg.wrapperWidth / newScale < $('.image-plain').width()) {
                            $('.eedition-scroller').width($('.image-plain').width());
                            iSeg.scrollTo(iSeg.x, (($('.image-plain').height() * curScale * centerRatio) - centerOffset) * -1, 0);
                        } else {
                            $('.eedition-scroller').width(iSeg.wrapperWidth / newScale);
                            var x = (($('.eedition-scroller').width() * curScale) - iSeg.wrapperWidth) / -2;
                            iSeg.scrollTo(x, (($('.image-plain').height() * curScale * centerRatio) - centerOffset) * -1, 0);
                        }
                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                        setTimeout(function(){
                            iSeg.refresh();
                        }, 250);

                    } else {
                        iSeg.zoom(newScale, iSeg.wrapperWidth / 2, iSeg.wrapperHeight / 2, 250);
                    }
                }
            }
        }
    };

    // Keyboard
    me.keyboard = {
        init: function() {
            var pageUuid = $('*[data-eedition]').data('eedition-uuid');
            if (me.utilities.viewMode(pageUuid) == 'text') {
                $(document).keydown(function(e) {
                    switch(e.which) {
                        /* Left arrow */
                        case 37:
                            me.navigation.navigate(eeData[pageUuid].prev);
                            break;
                        /* Up arrow */
                        case 38:
                            var scroll = $('#eedition-segments-container').scrollTop();
                            $('#eedition-segments-container').scrollTop(scroll - 10);
                            break;
                        /* Right arrow */
                        case 39:
                            me.navigation.navigate(eeData[pageUuid].next);
                            break;
                        /* Down arrow */
                        case 40:
                            var scroll = $('#eedition-segments-container').scrollTop();
                            $('#eedition-segments-container').scrollTop(scroll + 10);
                            break;
                        default:
                            return;
                    }
                });
            }
        }
    };

    // Navigation
    me.navigation = {

        // Initialize navigation
        init: function() {
        	var showThumbs;

            // Navigation opacity change for touch events
            $('.eedition-arrow.next, .eedition-arrow.prev').on('touchstart', function(){
                $(this).css('opacity', '1');
            });
            $('.eedition-arrow.next, .eedition-arrow.prev').on('touchend', function(){
                $(this).css('opacity', '0.5');
            });

            // Navigation Arrows
            $('.eedition-arrow.next, .eedition-arrow.prev').on('click', function() {
                showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                me.navigation.navigate($(this).data('eedition-uuid'), true, showThumbs);
            });
        },

        // Update buy now button
        buyNow: function(url, display) {
            /** Not currently supported */
            return;
            var button = $('.eedition-buy-btn'),
                buttonContainer = $('.eedition-buy-page');

            if (display) {
                // Change href attribute
                button.attr('href', url);

                // Show button is previous hidden
                if (buttonContainer.hasClass('hide')) {
                    buttonContainer.show();
                }
            } else {
                buttonContainer.hide();
            }
        },

        // Update menu, navigation, and primary image
        change: function(uuid) {
            var image,
                preloadImage,
                nextUuid = eeData[uuid].next;

            me.menu.update(uuid);
            me.navigation.update(uuid);

            // Subscription
            me.subscription.tieredAccess(uuid);

            // Image replace - Fall back to fullsize image if magnified isn't available
            image = (eeData[uuid].magnified) ? eeData[uuid].magnified : eeData[uuid].fullsize;
            me.image.replace(image);

            // Purchase URL - Update the hyperlink for the Buy Now button
            if (eeData[uuid].buy) {
                me.navigation.buyNow(eeData[uuid].buy, true);
            } else {
                me.navigation.buyNow(false, false);
            }

            // Preload next image - Fall back to fullsize image if magnified isn't available
            preloadImage = (eeData[nextUuid].magnified) ? eeData[nextUuid].magnified : eeData[nextUuid].fullsize;
            me.image.preload([preloadImage]);
        },

        // Navigate
        navigate: function(uuid, trackThumbs, pages) {
            var location;
            if (me.navigation.pageReload(uuid)) {
                if (pages) {
                    if (~eeData[uuid].url.indexOf('?')){
                        location = eeData[uuid].url + '&pages=true';
                    } else {
                        location = eeData[uuid].url + '?pages=true';
                    }
                } else {
                    location = eeData[uuid].url;
                }
                window.location.href = location;
                return
            } else {
                if (trackThumbs) me.iscroll.pages.scrollToElement("#eedition-page-" + uuid, '0');
                me.navigation.change(uuid);
                me.utilities.addHistory(eeData[uuid].url);
                me.utilities.pageview();
                me.utilities.virtualPageview(uuid);
                me.utilities.updateDocTitle(eeData[uuid].metaTitle);
            }
        },

        /* Page reload
            Determines whether the browser needs to be reloaded for segmented assets. */
        pageReload: function(uuid) {
            var currUuid = document.getElementById('eedition-data').dataset.eeditionUuid,
                currSection = eeData[currUuid].section,
                nextSection = eeData[uuid].section,
                forcePageviews = (document.getElementById('eedition-data').dataset.forcePageviews === 'true') ? true : false;

            if (uuid == currUuid || currSection != nextSection || document.getElementById('eedition-page-container').classList.contains('tiered-access') || forcePageviews) {
                return true;
            } else {
                var currSegments = eeData[currUuid].segments,
                    nextSegments = eeData[uuid].segments;

                if (currSegments && nextSegments) {
                    var currMode = me.utilities.viewMode(currUuid),
                        nextMode = me.utilities.viewMode(uuid);

                    if (currMode == 'image' && nextMode == 'image') {
                        return false;
                    } else {
                        return true;
                    }
                } else if ((currSegments && !nextSegments) || (!currSegments && nextSegments)) {
                    return true;
                } else if (!currSegments && !nextSegments) {
                    return false;
                }
            }
        },

        // Update navigation and data elements.
        update: function(uuid) {
            $('.eedition-arrow.next').data("eedition-uuid", eeData[uuid].next);
            $('.eedition-arrow.prev').data("eedition-uuid", eeData[uuid].prev);
            $('#eedition-data').data('eedition-uuid', uuid);
            $('.page-download').attr('href', eeData[uuid].resource);
        }

    };

    // Print Segment
    me.print = {

        init: function() {
            var printHold = false;
            $('.segment-social-print').click(function(e) {
                if (printHold === false) {
                    e.preventDefault();
                    var sPrintDiv = $(this).data('print-asset');

                    // Images
                    $(sPrintDiv+" .segment-images").each(function() {
                        $(this).trigger("appear");
                    });
                    setTimeout(function() {
                        $(sPrintDiv).print({
                            globalStyles: true,
                            timeout: 500
                        });
                    }, 1250);
                    printHold = true;
                } else {
                    return false;
                }
                setTimeout(function() {

                    // Release print hold
                    printHold = false;
                }, 1000);
            });
        }

    };

    // Two tier subscription support
    me.subscription = {

        downloads: function(display, type) {
            var downloadList = document.getElementsByClassName('sidebar-downloads-list')[0];

            if (display == 'show') {
                downloadList.classList.remove('hide');
            } else if (display == 'hide') {
                if (type == 'full edition') {
                    downloadList.querySelector('.edition-download').parentNode.classList.add('hide');
                } else {
                    downloadList.classList.add('hide');
                }
            }
        },

        hasPremiumSection: function() {
            for (var i in eeData) {
                if (eeData[i].premiumSection === true) return true;
            }
            return false;
        },

        hasServices: function() {
            if (document.getElementById('eedition-wrapper').classList.contains('subscriber-only')) {
                return true;
            }
            return false;
        },

        isSuperUser: function() {
            var superUser = false;

            if (__tnt.user.services) {
                var bloxUserServiceIDs = __tnt.user.services.replace('%2C',',').split(',');
                bloxUserServiceIDs.forEach(function(value) {
                    if (value == 0) superUser = true;
                });
            }
            return superUser;
        },

        modal: function(func) {
            var pageContainer = document.getElementById('eedition-page-container');

            if (func == 'load') {
                if (typeof document.getElementsByClassName('tiered-access-modal')[0] == 'undefined') {
                    var modal = document.createElement('div'),
                        template = document.getElementById('tiered-access');

                    modal.classList.add('tiered-access-modal');
                    if ('content' in document.createElement('template')) {
                        modal.appendChild(template.content.cloneNode(true));
                    } else {
                        // IE Polyfill
                        var children = template.childNodes;

                        for (var i = 0; i < children.length; i++) {
                            modal.appendChild(children[i].cloneNode(true));
                        }
                    }
                    pageContainer.appendChild(modal);
                }
                document.getElementsByClassName('tiered-access-modal')[0].style.display = 'block';
                pageContainer.classList.add('tiered-access');
            } else if (func == 'hide') {
                if (typeof document.getElementsByClassName('tiered-access-modal')[0] != 'undefined') {
                    if (pageContainer.classList.contains('tiered-access')) {
                        document.getElementsByClassName('tiered-access-modal')[0].style.display = 'none';
                        pageContainer.classList.remove('tiered-access');
                    }
                }
            }
        },

        tieredAccess: function(uuid) {
            if (eeData[uuid].premiumSection) {
                if (Cookies.get('leefalcon_membership') != 'PLAT' && !this.isSuperUser()) {
                    this.modal('load');
                    if (eeData[uuid].pdfRestriction) this.downloads('hide', null);
                }
            } else {
                this.modal('hide');
                if (this.hasPremiumSection() && Cookies.get('leefalcon_membership') != 'PLAT' && eeData[uuid].pdfRestriction) this.downloads('hide', 'full edition');
            }
        }

    };

    // System
    me.system = {

        checkPreview: function() {
            return window.location.pathname.indexOf(previewSection);
        },

        baseUrl: function() {
            var previewMode = me.system.checkPreview();
            if (previewMode !== -1) {
                var domainName = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
                return protocol + hostname + '/' + domainName + previewSection;
            } else {
                return protocol + hostname;
            }
        },

        contentUrl: function () {
            var previewMode = me.system.checkPreview();
            if (previewMode !== -1) {
                var domainName = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
                return protocol + hostname + '/' + domainName + previewContent;
            } else {
                return protocol + hostname;
            }
        }

    };

    // Utilities
    me.utilities = {

        addHistory: function(url, callback) {
            history.pushState(null, '', url);

            if (callback && typeof callback === 'function') {
                callback();
            }
        },

        availableWidth: function() {
            if ($('#eedition-thumbnails-container').is(':visible')) {
                return $(window).width() - $('#eedition-thumbnails-container').width();
            } else {
                return $(window).width();
            }
        },

        availableHeight: function() {
            var height = $(window).height() - $('#eedition-mainmenu').outerHeight() - $('#opt-asset-content-top').outerHeight() - $('#opt-asset-content-bottom').outerHeight();
            
            if ($('.eedition-bottombar').hasClass('active')) {
                height = height - $('.eedition-bottombar-main').outerHeight();
            }
            
            var page_bottom = document.getElementById('eedition-page-bottom');
            if (page_bottom && page_bottom.offsetHeight > 0) {
                height = height - page_bottom.offsetHeight;
            }
            
            var page_top = document.getElementById('eedition-page-top');
            if (page_top && page_top.offsetHeight > 0) {
                height = height - page_top.offsetHeight;
            }

            return height;
        },

        getUrlVars: function() {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },

        getUrlVar: function(name) {
            return me.utilities.getUrlVars()[name];
        },

        gtmDownloadEvent: function(label, link_text, link_url) {
            window.dispatchEvent(new CustomEvent('BLOXFileDownload', {
                detail: {
                    action: 'pdfDownload',
                    category: 'e-edition',
                    label: label,
                    link_text: link_text,
                    link_url: link_url
                }
            }));
        },

        gtmTracking: function() {
            var mode = me.utilities.viewMode($('#eedition-data').data('eedition-uuid'));
            if (window.matchMedia('(orientation: portrait)').matches || $(window).width() < $(window).height()) {
                dataLayer.push({'tncms':{'eedition':{'view':'unified view'}}});
            } else if (mode == 'image') {
                dataLayer.push({'tncms':{'eedition':{'view':'page view'}}});
            } else if (mode == 'text') {
                dataLayer.push({'tncms':{'eedition':{'view':'text view'}}});
            }
        },

        hasSegments: function() {
            var UUID = document.getElementById('eedition-data').getAttribute('data-eedition-uuid');
            if (eeData[UUID].segments) {
                return true;
            }
            return false;
        },

        isOnScreen: function(el) {
            var el = $(el),
                menuBuffer = $('#eedition-mainmenu').outerHeight(),
                win = $(window),
                viewport = {
                    top : win.scrollTop() + menuBuffer,
                    left : win.scrollLeft()
                };

            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();

            var bounds = el.offset();
            bounds.right = bounds.left + el.outerWidth();
            bounds.bottom = bounds.top + el.outerHeight();

            return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
        },

        isZoom: function() {
            var modeCookie = Cookies.get('__tnt_eedition_mode');
            if ((!modeCookie || modeCookie === 'text') && $('#eedition-segments').length > 0) {
                return false;
            } else {
                return true;
            }
        },

        pageview: function() {
            __tnt.trackEvent({
                category: 'eedition',
                label: 'e-Edition Page view',
                action: 'page_load'
            });
        },

        positionBottombarButton: function() {
            var page_bottom = document.getElementById('eedition-page-bottom');
            if (page_bottom && page_bottom.offsetHeight > 0) {
                if (window.innerWidth >= 480) {
                    $('.eedition-bottombar-btn').css('top', (-40 - page_bottom.offsetHeight) + 'px');
                } else {
                    $('.eedition-bottombar-btn').css('top', (-60 - page_bottom.offsetHeight) + 'px');
                }
            } else {
                $('.eedition-bottombar-btn').css('top', '');
            }
        },

        updateDocTitle : function(title) {
            $(document).attr('title', title);
        },

        urlParam : function(name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results == null) {
                return null;
            } else {
                return results[1] || 0;
            }
        },

        viewMode: function(uuid) {
            var modeCookie = Cookies.get('__tnt_eedition_mode'),
                hasSegments = eeData[uuid].segments,
                mode = null,
                portrait = window.matchMedia('(orientation: portrait)').matches || $(window).width() < $(window).height();

            if (!hasSegments) {
                mode = 'image';
            } else if (portrait) {
                mode = 'text';
            } else if (modeCookie) {
                mode = modeCookie;
            } else if (defaultMode) {
                mode = defaultMode;
            } else {
                mode = 'text';
            }
            return mode;
        },

        virtualPageview: function(uuid) {
            var trackObject = {
                title: eeData[uuid].title,
                url: eeData[uuid].url,
                uuid: uuid,
                app: 'eedition',
                metric: ''
            };

            __tnt.trackEvent(trackObject);

            if (me.refreshPageviewAds && typeof me.refreshPageviewAds === 'function') {
                me.refreshPageviewAds();
            }
        }

    };

    // Overridable functions
    if (!__tnt.eedition.refreshPageviewAds && typeof __tnt.eedition.refreshPageviewAds !== 'function') {
        __tnt.eedition.refreshPageviewAds = function () {
            try {
                if (typeof gptAdSlots != 'undefined') {
                    var refreshableAds = [];

                    var pageTop = document.getElementById('eedition-page-top');
                    var pageBottom = document.getElementById('eedition-page-bottom');

                    if (pageTop || pageBottom) {
                        if (pageTop) {
                            var topAdEls = pageTop.querySelectorAll('.tnt-ads');
                            for (var i=0; i<topAdEls.length; i++) {
                                if (topAdEls[i].offsetHeight > 0) {
                                    refreshableAds.push(gptAdSlots[topAdEls[i].id]);
                                }
                            }
                        }

                        if (pageBottom) {
                            var bottomAdEls = pageBottom.querySelectorAll('.tnt-ads');
                            for (var i=0; i<bottomAdEls.length; i++) {
                                if (bottomAdEls[i].offsetHeight > 0) {
                                    refreshableAds.push(gptAdSlots[bottomAdEls[i].id]);
                                }
                            }
                        }

                        if (refreshableAds.length > 0) {
                            googletag.cmd.push(function() {
                                //googletag.pubads().clear(refreshableAds);
                                googletag.pubads().refresh(refreshableAds);
                            });
                        }
                    }
                }
            } catch (e) {
                __tnt.log(e);
            }
        };
    }

})(window, document, jQuery, __tnt);